export const Footer = (props) => {
  return (
    <div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; 2022 Kitty Kad</p>
        </div>
      </div>
    </div>
  );
};
