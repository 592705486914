export const SCREENS = {
  BUY: "BUY",
  MY_KITTIES: "MY_KITTIES",
  ALL_KITTIES: "ALL_KITTIES",
  DETAILS: "DETAILS",
  BREED: "BREED",
  GEN_1_KITTIES: "GEN_1_KITTIES",
};

export const KITTY_ACTIONS = {
  CAN_BUY: "CAN_BUY",
  LOGIN_TO_BUY: "LOGIN_TO_BUY",
  REMOVE_FROM_SALE: "REMOVE_FROM_SALE",
  PUT_ON_SALE: "PUT_ON_SALE",
  TRANSFER: "TRANSFER",
};

export const SORT_KEYS = {
  LOWEST_PRICE: "LOWEST_PRICE",
  HIGHEST_PRICE: "HIGHEST_PRICE",
  LOWEST_ID: "LOWEST_ID",
  HIGHEST_ID: "HIGHEST_ID",
};

// const CONSTS = { SCREENS };
// export default CONSTS;
